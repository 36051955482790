const en = {
  buttons: {
    askForDemo: "Request a Demo",
    login: "Login",
  },
  labels: {
    name: "Name",
    lastname: "Lastname",
    nameLastname: "Name & Lastname",
    email: "E-mail",
    phone: "Phone",
    company: "Company",
    workTitle: "Title",
    address: "Subject",
    adress: "Adress",
    message: "Message",
    solution: "Solution",
    calculate: "Calculation",
    app: "App",
    more: "more about",
  },
  navbar: {
    home: "Home",
    solutions: "Solutions",
    whyUpu: "Why upu ?",
    products: "Products",
    contact: "Contact",
  },
  heroSection: {
    onlyOneDevice: "With a single hardware device",
    title: "Create Digital Twin of Your Factory with",
    subTitle:
      ",you can access all performance, maintenance, loss, and energy usage data, production personnel analysis, and production data of all equipment in your factory, regardless of model, year, or type, simultaneously or archived, from any computer, tablet, or phone, at any time.",
  },
  carbon: {
    title: "We Follow the Traces Left in the World",
    subtitle:
      "automatically calculates the carbon footprint of your product with a brand new technology",
  },
  solutions: {
    tag: "Solutions",
    title: "Your machines, employees, and products are all at your fingertips.",
  },
  whyUpu: {
    tag: "Why upu ?",
    title:
      "Because upu.io is the quickest way to transition your production to Industry 4.0",
    wireless:
      "Connection is established via ethernet, WI-FI, cellular or local networks",
    plugAndPlay:
      "It is ready to use as soon as it is installed, without any intervention or machine integration",
    quickSetup: "It allows you to digitize your factory in a single day",
    compatibility:
      "Any brand and model of machinery, both new and old, can use a sensor and light tower",
    cheap:
      "Installation costs for wiring are not necessary for engineering integration.",
    accessibility: "Any device can be used to view and manage it",
    cloudBase:
      "Anywhere, using any device, you can instantly establish a secure connection, storing your data safely",
    endToEnd:
      "Your issues can be resolved on a single platform by combining hardware and software solutions",
    touchlessSetup:
      "Without interfering with the electronic systems of your machines, it produces data using its own system",

    wirelessTitle: "Wireless",
    plugAndPlayTitle: "Plug and Play",
    quickSetupTitle: "Quick Setup",
    compatibilityTitle: "Compatible with All Machines",
    cheapTitle: "Affordable Prices",
    accessibilityTitle: "Access from Any Device",
    cloudBaseTitle: "Cloud Based",
    endToEndTitle: "End-to-End Solution",
    touchlessSetupTitle: "Uninterrupted Setup",
    easiest: "Easiest Way to Digitilize Your Factory",
    easiestText1: "Rebuild your business with next-generation technologies.",
    easiestText2:
      " , a single IIoT device that is simply installed on all old and new machines in your factory without any electrical interference, is easily installed and then you can access all your machines (",
    easiestText3: "), your personnel (",
    easiestText4: ") and your products (",
    easiestText5:
      "). You can access and manage all data from anywhere via your computer, phone or tablet.",
  },
  products: {
    tag: "Products",
    title: "Upu products that will move your manufacturing to Industry 4.0",
  },
  allProducts: {
    title: "End-to-end IIOT solution",
    tower:
      "Upu Tower, processes all machine data with artificial intelligence supported sensor fusing and transmits it to employees without the need for a gateway, by easily connecting to you with its own sensor sets without interfering with your machines.",
    display:
      "Instead of traditional part identification cards, NFC smart tag product developed by  e-ink technology.",
    lite: "Without requiring the light tower features of upu.tower, it automatically generates all status data and transfers it over ethernet or wireless.",
    sense: "IIOT sensor sets are compatible with all machines",

    current:
      "0-500 A interleaved sensitive intelligent current sensor with 4-20 mA analog outputs.",
    ldr: "Intelligent IIOT light sensor with adjustable analog and digital outputs.",
    prox: "PNP and NPN intelligent IIOT proximity sensor.",
    vibra:
      "Artificial intelligence supported IIOT Vibration sensor with 4-20 mA output for predictive maintenance activities.",
    temp: "Intelligent and sensitive IIOT temperature sensor with 4-20 mA analog output.",
    press:
      "Intelligent and sensitive IIOT pressure sensor with 4-20 mA analog output.",
  },
  tower: {
    title: "Get a bird's eye view of your manufacturing",
    connection: "Connection",
    technology: "Technology",
    lightTower: "Light Tower",
    integratedSystem: "Integrated System",
    wifiEthernet: "Wifi, Ethernet",
    digitalInput: "Digital Input",
    digitalOutput: "Digital Output",
    analogInput: "Analog Input",
    sensorFusing:
      "Sensor fusing, provides a machine learning decision-making solution by filtering, combining, and processing all sensor data within itself.",
    ligtColor: "16M light color options",
    andonButton: "Andon Button",
    subTitle:
      "It has the feature of providing both wireless and wired connection with the Wi-Fi module and ethernet input on upu.tower. With 4 analog and 4 digital inputs, the machine carries your data to the system regardless of format.",
    dropdownTitle: "Wi-Fi Sensor Fusing Signal Light Tower",
  },
  sense: {
    title: "Plug and Play Smart IIOT Sensor Products",
    current: "Intelligent IIOT Current Sensor",
    ldr: "Intelligent IIOT LDR Sensor",
    prox: "Intelligent IIOT Proximity Sensor",
    vibra: "Intelligent IIOT Vibration Sensor",
    temp: "Intelligent IIOT Temperature Sensor",
    press: "Intelligent IIOT Pressure Sensor",
  },
  display: {
    title: "NFC E-Label Display",
  },
  lite: {
    title: "Wi-Fi Data Box",
  },
  dashboard: {
    tag: "Interface Images",
    title: "Interface Images from upu.io System",
  },
  social: {
    tag: "Social Medias",
    title: "Follow Our Accounts For Up-to-Date Information About upu.io",
  },
  footer: {
    title:
      "With a single hardware device(upu.tower) ,you can access all performance, maintenance, loss, and energy usage data, production personnel analysis, and production data of all equipment in your factory, regardless of model, year, or type, simultaneously or archived, from any computer, tablet, or phone, at any time.",
    keepInTouch: "Keep in touch",
    rightsReserved: "© 2022 upu.io All Rights Reserved.",
  },
  solutionsModal: {
    //machine
    twin: "With upu.twin, you can easily create your factory, set up your machines, see your production from above, instantly and retroactively analyze all of your data.",
    kpi: "For The Entire Company : *Teorical Production Time, *Planned Production Time. For Every Process and Machines : *Avaibility *Performance *Utilization For Every Personnel : *Performance It calculates your data momentary, per shift, daily, weekly, and yearly, archives, and analyzes this data for you. ",
    energy:
      "Analysis and solutions for electrical energy consumption on a moment-by-moment, daily, weekly, monthly, and annual basis are given for each equipment.",
    loss: "The performance and usability losses of your employees and machines, their root causes, and the cost incurred due to these losses are automatically listed and reported for you.",
    report:
      "It automatically generates daily, weekly and monthly reports for you. In addition, you can access all the analysis data you want at any time with a very easy and user-friendly report generation screen.",
    calibrate: "",
    shift:
      "It provides a new generation of shift management solutions that allow you to compare and track performance for each of the shifts by managing your company's working shifts.",
    //person
    point:
      "It is a pointing system where you can compare your employees’ work performance and the performance KPI to be automatically generated through the system, with the leaderboard on a weekly, monthly, yearly, and daily basis, and reward them with a bonus.",
    // care: "With the smart maintenance tracking solution provided by upu.care, you can keep track of and schedule the upkeep of your equipment in real-time.",
    message:
      "With the in-company corporate messaging solution, you can reach all your personnel instantly and securely.",
    talent:
      "You can keep track of your personnel’s advancement by archiving their training and abilities.",
    poll: "You can create customized surveys for your employees to increase employee satisfaction, and you can respond to suggestions and complaints right away thanks to the in-company survey system.",
    notify:
      "You can create notifications for events and actions that occured in your company. Also you can send those notifications to personnels that you can specify.",
    task: "You can manage all the tasks that given in your company or department easily with this service.",
    attend:
      "You can view the arrival and departure times, as well as the status of your employees' leaves, and you can also report an employee's monthly working hours.",
    //product
    part: "",
    supply:
      "It digitizes your procurement processes in every aspect and seamlessly integrates your suppliers into your digital smart manufacturing processes.",
    cost: "",
    quality:
      "All quality control processes of your manufacturing operations are digitized and tracked. Your quality control data is automatically stored and analyzed on a product-centric basis.",
    assist:
      "The entirely cloud-based upu.io software provides 24/7 support whenever you need it. Our training team will be with you until your personnel acquire the necessary competencies.",
    api: "The infrastructure that will improve compatibility with all ERP and business process software is available to customers through upu.io.",
    countT:
      "The greatest environmental and technological innovation of upu.io is its ability to obtain resource consumption data during production independent of machines, and automatically calculate the product-level carbon footprint. This is achieved by standardizing the data obtained from machines and enabling holistic management of energy, machinery, personnel, and product resource data without the need for a separate infrastructure.",
    stockT:
      "It digitizes your active inventory control. Real-time tracking of inventory and intermediate products is possible.",
    orderT:
      "All the processes from order to delivery of your products are prepared for end-to-end smart manufacturing with the kanban method and our enhanced solution.",
  },
  pyramid: {
    head: "We Are Rebuilding the Automation Pyramid",
    text: "The Traditional Automation Pyramid defines the functional hierarchy of your company's level of digitalization (ISA-95) in 5 levels. According to this defined automation pyramid, engineering applications are required between each automation level in factories. This means that every step to raise the automation level comes with high costs for companies that want to improve their automation level. This is where upu.io's end-to-end innovative solution comes in and helps companies looking to digitize and enter the Industry 4.0 world by making their work easier with a cloud-based next-generation architecture at low cost.",
  },
  story: {
    title: "The Story of",
    subTitle:
      "upu.io is a cloud-based next-generation cyber-physical production management platform that will revolutionize your production and change your perspective on manufacturing. It provides end-to-end smart manufacturing solutions to digitize your production process.",
    text: "The name upu.io is derived from the hoopoe bird, the sole member of the Upupidae family in the Coraciiformes order. Known for its distinctive crest and unique vocalizations, this bird is a rare species with historical significance. Throughout history, the beautiful hoopoe has been considered sacred in various traditional sources and is recognized as the messenger bird of King Solomon. We envision upu.io as a messenger that effectively communicates everything to executives, much like Solomon, even if they may not speak the language of 'bird' (data). The '.io' signifies the digital dual direction of data (input and output) within our platform.",
  },
  modalText: {
    //machine
    twin: "The first digital solution package, allows you to easily draw your factory plan. This way, you can group your machines according to your processes and view their actual locations at a bird's-eye view. It also provides real-time monitoring of your factory.",
    kpi: "It calculates your data momentary, per shift, daily, weekly, and yearly, archives, and analyzes this data for you.",
    energy:
      "Analysis and solutions for electrical energy consumption on a moment-by-moment, daily, weekly, monthly, and annual basis are given for each equipment.",
    care: "The greatest environmental and technological innovation of upu.io is its ability to obtain resource consumption data during production independent of machines, and automatically calculate the product-level carbon footprint. ",
    loss: "The performance and usability losses of your employees and machines, their root causes, and the cost incurred due to these losses are automatically listed and reported for you. ",
    report:
      "It automatically generates daily, weekly and monthly reports for you. In addition, you can access all the analysis data you want at any time with a very easy and user-friendly report generation screen.",
    shift:
      "It provides a new generation of shift management solutions that allow you to compare and track performance for each of the shifts by managing your company's working shifts.",

    //person
    point:
      "It is a pointing system where you can compare your employees’ work performance and the performance KPI to be automatically generated through the system, with the leaderboard on a weekly, monthly, yearly, and daily basis, and reward them with a bonus.",
    // care: "With the smart maintenance tracking solution provided by upu.care, you can keep track of and schedule the upkeep of your equipment in real-time.",
    message:
      "With the in-company corporate messaging solution, you can reach all your personnel instantly and securely.",
    talent:
      "You can keep track of your personnel’s advancement by archiving their training and abilities.",
    poll: "You can create customized surveys for your employees to increase employee satisfaction, and you can respond to suggestions and complaints right away thanks to the in-company survey system.",
    notify:
      "You can create notifications for events and actions that occured in your company. Also you can send those notifications to personnels that you can specify.",
    task: "You can manage all the tasks that given in your company or department easily with this service.",
    attend:
      "You can view the arrival and departure times, as well as the status of your employees' leaves, and you can also report an employee's monthly working hours.",
    //product
    cCount:
      "The greatest environmental and technological innovation of upu.io is its ability to obtain resource consumption data during production independent of machines, and automatically calculate the product-level carbon footprint. This is achieved by standardizing the data obtained from machines and enabling holistic management of energy, machinery, personnel, and product resource data without the need for a separate infrastructure.",
    part: "",
    supply:
      "It digitizes your procurement processes in every aspect and seamlessly integrates your suppliers into your digital smart manufacturing processes.",
    cost: "",
    quality:
      "All quality control processes of your manufacturing operations are digitized and tracked. Your quality control data is automatically stored and analyzed on a product-centric basis.",
    assist:
      "The entirely cloud-based upu.io software provides 24/7 support whenever you need it. Our training team will be with you until your personnel acquire the necessary competencies.",
    api: "The infrastructure that will improve compatibility with all ERP and business process software is available to customers through upu.io.",
    count:
      "The greatest environmental and technological innovation of upu.io is its ability to obtain resource consumption data during production independent of machines, and automatically calculate the product-level carbon footprint. This is achieved by standardizing the data obtained from machines and enabling holistic management of energy, machinery, personnel, and product resource data without the need for a separate infrastructure.",
    stock:
      "It digitizes your active inventory control. Real-time tracking of inventory and intermediate products is possible.",
    order:
      "All the processes from order to delivery of your products are prepared for end-to-end smart manufacturing with the kanban method and our enhanced solution.",
  },
  machinePackage: {
    title: "Machine Monitoring Package",
    subTitle:
      "You will be able to manage your maintenance in addition to accessing performance data, energy analysis, and loss information for all of your machines.",
    twin: "Digital Twin",
    machineTwin: "Machine Twin Solution",
    kpi: "Key Performance Indicator",
    energy: "Energy Analizing",
    loss: "Work Loss Tracing Solution",
    report: "Periodic Reporting Solution",
    calibrate: "Equipment Calibration",
    care: "Maintenance Management",
    shift: "Shift Management",
  },
  personPackage: {
    title: "Personnel Management Package",
    subTitle:
      "You will have access to your employees' performance data with ready-made modules such as messaging, surveys, and suggestions, allowing you to compare them.",
    newSubtitle:
      "Personnel Twin page allows you to track personnel performance on a daily, weekly and monthly basis. It provides detailed insights into performance rates and upu.points earned from completed tasks, helping you manage your workload more efficiently.",
    personTwin: "Person Twin Solution",
    point: "Personnel Effecient Scoring",
    care: "Carbon Footprint Calculation",
    message: "Corporate Messaging App",
    talent: "Talent Management Solution",
    attend: "Personnel Attendance Control",
    poll: "Enterprise Survey App",
    notify: "Instant Notification  System",
    task: "Task Management",
  },
  productPackage: {
    title: "Production Management Package",
    productTwin: "Work in Process",
    productPlanning: "Product Planning",
    productTitle: "Product Twin Solution (WIP)",
    productSubtitle:
      "By digitizing your production process with the Kanban method, you can monitor all your operations step by step. With the Product Twin, you can control your production line processes in real time and enhance your production efficiency.",
    planningTitle: "Production Planning Solution",
    planningSubtitle:
      "You can schedule products from opened orders for specific operations and assign the machines involved in those operations. By organizing your production processes step by step, you can enhance operational efficiency and make better use of your resources.",
    subTitle:
      "You will be able to take advantage of all of upu.io's features, which cover every aspect of your production and include production tracking, supply management, and cost analysis.",
    cCount: "Carbon Footprint Calculation",
    part: "Production Management Solution",
    supply: "Supply Management Solution",
    cost: "Production Cost Solution",
    quality: "Quality Operations Management ",
    assist: "24/7 Service and Education",
    api: "API Service",
    stock: "Stock Management Solution",
    order: "Order Management Solution",
    count: "Carbon Footprint Calculation",
  },
};

export default en;
